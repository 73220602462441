@font-face {
    font-family: 'poppins';
    src: local('poppins')
}

html,body{
    height: 100%;
}

#root{
    height: 100%;
}

.container{
    padding: 0 0 0 7.5% !important;
    margin: 0;
    background-color: #F0F2FF;
    max-width: 100% !important;
    position: relative;
    height: 100%;
}

.normal-container{
    padding: 0 0.9rem;
    margin: 0;
    width: 100%;
    position: relative;
    height: calc(100% - 4rem);
}

.nav-container{
    top: 10px
}

.lbic-footer{
    background-color: #373636;
    padding: 80px 8%!important;
    color: white;
    max-width: 100%!important;
}

.text-align-left{
    text-align: left;
}

.text-align-center{
    text-align: center;
}

.text-align-right{
    text-align: right;
}

.link{
    text-decoration: none;
    color: white;
}

.link:hover{
    color: white;
}

.large-text{
    font-size: 30px!important;
}

.normal-text{
    font-size: 17px!important;
    line-height: 1.2!important;
}

.medium-text{
    font-size: 23px!important;
}

.small-text{
    font-size: 13px!important;
}

.extra-small{
    font-size: 10px;
}

.bold{
    font-weight: bold!important;
    letter-spacing: 1.5px!important;
}

.semi-bold{
    font-weight: 500!important;
}

.regular{
    font-weight: 300!important;
}

.light{
    font-weight: 100!important;
}

.black-light{
    color:rgb(177, 177, 177);
}

.black-medium{
    color:rgb(77, 76, 76);
}

.black-deep{
    color:rgb(0, 0, 0);
}

.top{
    top: 120px;
    position: relative;
}

.custom-input div{
    border-radius: 50px;
}

.custom-input div fieldset legend{
    display: none;
}

    .invoice {
        padding: 30px;
    }
    
    .invoice h2 {
        margin-top: 0px;
        line-height: 0.8em;
    }
    
    .invoice .small {
        font-weight: 300;
    }
    
    .invoice hr {
        margin-top: 10px;
        border-color: #ddd;
    }
    
    .invoice .table tr.line {
        border-bottom: 1px solid #ccc;
    }
    
    .invoice .table td {
        border: none;
    }
    
    .invoice .identity {
        margin-top: 10px;
        font-size: 1.1em;
        font-weight: 300;
    }
    
    .invoice .identity strong {
        font-weight: 600;
    }
    
    
    .grid {
        position: relative;
        width: 100%;
        background: #fff;
        color: #666666;
        border-radius: 2px;
        margin-bottom: 25px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
    }

@keyframes line {
    0% { width: 0%; right: 75%; left: 0%;}
    20% {width: 25% ; right: 45%; left: 30%;}
    40% {width: 35%; right: 30%; left: 35%;}
    60% { width: 40% ; right: 35%; left:35%;}
    80% { width: 30%; right: 25%;left: 45% }
    100%{width: 25%; right: 0%; left: 75%; }
}
.loading-anim{
    background-color: #42B549;
    width: 0;
    left: 0;
    height: 8px;
    animation-name: line;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-timing-function:linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    position: relative;
    top: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow-x:hidden;
}

.no-loadin-anim{
    width: 0px;
    display: block;
}

.header{
    display: flex !important;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: space-between;
    max-width: 100% !important;
    padding-right: 0!important;
    padding-top: 69px;
    padding-bottom: 20%;
}

.text-area{
    display: flex!important;
    flex-direction: column;
    /* width: 42%;
    margin-right: 8%; */
}

.btn-register{
    padding: 20px 40px !important;
    width: fit-content;
    border-radius: 100px!important;
    background-color: #42B549!important;
    color: white!important;
}

.image-area{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: end;
}

.image-area img{
    width: 70%;
}

.typography{
    text-decoration: none;
    font-family: 'poppins'
}

.typography span{
    color: #5a5959;
    font-weight: 500;
}

.typography_active{
    color: #42B549 !important;
    border-bottom: 2px solid  #42B549 !important;
}

.column{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-width{
    width: 60%;
}

.item{
    width: 50%;
    padding: 0 3%
}

.select-label{
    top: -10px
}

.select-form-control{
    top: 9px
}

.upload-container{
    border: 1px dashed #42B549;
    padding: 20px 20px 20px 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.upload-btn{
    opacity: 0;
    z-index: 10;
    position: absolute!important;
    top: 30px;
    height: 100px;
    display: block;
}

.container-dashboard{
    padding-left: 0!important;
    padding-right: 0!important;
    max-width: 100%!important;
}

.cover-details{
    padding: 10px 20px
}

.left-border{
    border-right: 0.5px solid;
}

.buy-button{
    padding: 10px 30px!important;
    border-radius: 50px !important;
    border: 1px solid!important;
    color: #000000!important;
    width: 50%;
}

.buy-button:hover{
    background-color: #42B549!important;
    color: #ffffff!important;
}

.more-details{
    max-width: 64px;
}

.dropdown{
    display: none;
}

.hover{
    display: block;
    position: absolute;
    z-index: 10;
    top: 10px;
    width: 96%;
    left: 5px;
    background: #fbfbfbbf;
    padding: 8px 10px;
    border-radius: 10px 10px 0 0;
}

.active-drop{
    display: block;
    animation-name: slowdrop;
    animation-duration: 2s;
    animation-timing-function: ease-in;
}

.close-activedrop{
    display: none;
    animation-name: closedrop;
    animation-duration: 1s;
    animation-timing-function: ease-in;
}

#invoice-footer{
    width: 80%;
    margin: 0 auto;
}

#invoice-footer div:first-child{
    padding-left: 0;
}

@keyframes slowdrop {
    from {height: 0px;}
    to {height: 100px;}
}

@keyframes closedrop {
    from {height: 100px; display: block;}
    to {height: 0px; display: none;}
}

.heading-text{
    font-size: 40px;
    line-height: 1.3;
    font-weight: 700;
}

.actionbtn{
    cursor: pointer;
    background-color: #F0F2FF;
    padding: 6px 10px 10px 10px ;
    border-radius: 50px;
}

.actionbtn:hover{
    background-color: #ddd;
}

.responsive-menu-off, .responsive-menu{
    display: none
}

.mobile-menu{
    display: none
}

.sign-in{
    width: 35%!important;
}

.information-container,.dashboard-heading{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.information-container{
    margin-top: 3rem;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 25px;
}

.btn-edit-profile{
    position: relative;
    color: #42B549;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;
}

.btn-edit-profile:hover{
    color: #42B549;
    background-color: #ddd;
}

.property-menu{
    background-color: #0000001c !important;
    color: #ffffff !important;
    border-radius: 0 !important;
    font-weight: 100 !important;
}

.property-shadow{
    box-shadow: -3px 2px 3px rgb(186, 183, 183), -2px 4px 3px rgb(58, 58, 58);
}
/* --------------- */
/* breakpoints */


@media (max-width: 425px) {
    .tablet-view{
        padding: 3%!important;
        color: #fbf3f3!important;
    }
    .item, .text-area, .sign-in{
        min-width: 100%!important;
    }
    .text-area{
        background: #0000007c;
        padding: 10px;
    }
    .heading-text{
        font-size: 25px!important;
    }
    .normal-text{
        font-size: 14px!important;
    }
    .btn-register{
        padding: 10px 18px !important;
    }
    .column{
        flex-direction: column;
    }
    .button-center{
        width: fit-content;
        margin: auto
    }
    .sign-in{
        top: 80px
    }
    #invoice-footer{
        margin-left: auto;
        margin-right: auto;

    }
}

@media (max-width: 575px){
    .container-dashboard{
        position: relative;
        top: 55px!important;
    }
    .cover-details{
        align-items: flex-start;
        flex-direction: column!important;
    }
    .left-border,.cover-action{
        justify-content: space-between!important;
    }
    .left-border{
        border-right: none;
        flex-wrap: wrap;
    }
    .cover-action{
        display: flex;
        flex-direction: row;
    }
    .cover-action div p{
        text-align: left;
        font-size: 18px!important;
        line-height: 1.1rem;
    }
    .cover-right, .left-border{
        width: 100%!important;
        padding: 0 4%;
    }
    .buy-button{
        padding: 7px 25px!important;
        min-height: 0!important;
        margin-top: auto!important;
    }
    .cover-action div p:nth-child(2){
        font-weight: bold!important;
        font-size: 16px!important;
    }
    .medium-text{
        font-size: 17px!important;
    }
}

@media (max-width: 768px) {
    .container{
        padding: 0 0 0 0!important;
    }
    .heading-text, .normal-text{
        color: inherit!important
    }
    .heading-text{
        font-size: 28px;
    }
    .tablet-view{
        width: 100%;
        background-image: url('../landscape.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 3% 0 8% 8%!important;
        color: #fbf3f3!important
    }
    .text-area{
        background: #0000007c;
        padding: 10px;
    }
    .card-width{
        width: 100%!important;
    }
    .sign-in{
        width: 60%!important;
    }
    .sign-in,.card-width{
        top: 80px
    }
    .left-border div p{
        font-size: 15px!important;
    }
    .cover-details{
        width: 100%!important;
    }
    .information-container,.dashboard-heading{
        width: 90%;
    }
}

@media (min-width: 769px) and (max-width: 1000px){
    .sign-in{
        width: 50%!important;
        top: 90px
    }
}


@media (max-width: 767px){
    .download_section{
        display: none;
    }
    .button-center{
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 991px) {
    .menuitem{
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
        color: #000000;
    }

    .menuitem:hover{
        background-color: #42B549;
        color: #fff;
    }
}

@media (max-width: 1024px) {
    .header{
        padding-top: 60px;
        padding-left: 0!important;
    }
    .image-area{
        width: 50%;
    }
    .image-area img{
        width: 100%;
    }
    .text-area{
        width: 55%;
    }
    .column{
        margin: 0!important;
    }
    .card-width{
        width: 80%;
    }
}

@media (min-width: 1400px){
    .sign-in{
        width: 25%!important;
        top: 20px
    }
}
@media (max-width: 1199px){
    .responsive-menu{
        display: block;
        width: fit-content;
        height: calc(100vh - 6rem);
        background-image: linear-gradient(195deg, #42424a, #191919);
        z-index:1200;
        position: fixed;
        top: 0px;
        margin-top: 5.5rem;
        right: 1.5rem;
        border-radius: 5px;
    }

    .responsive-menu nav{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding: 10px 0;
    }

    .mobile-bottom{
        margin-top: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mobile-bottom button{
        background-color: #42B549;
        color: white;
        width: 80%;
    }

    .mobile-bottom button:hover{
        background-color: #42B549;
    }

    .responsive-menu nav ul{
        width: max-content;
        list-style: none;
        margin: 0;
        padding: 5px;
    }

    .mobile-menu-item{
        width: 100%;
        margin: 0;
        border-bottom: 1px solid rgba(213, 212, 212, 0.661);
        cursor: pointer;
        color: white;
        font-weight: 500;
    }

    .mobile-menu-item:hover{
        background-color: #5cde65;
        border-radius: 5px;
        border-bottom: none;
        color: #323131;
        font-weight: 600;
    }

    .mobile-menu-item a{
        text-decoration: none;
        color: inherit;
        display: block;
        padding: 0.6rem 1.5rem 0.6rem 1.5rem;
    }

    .mobile-menu{
        display: block
    }
}

@media print{
    .sidenav,#invoice-footer,.navheader{
        display: none!important;
    }
}



